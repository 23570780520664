import React, { Component, useEffect, useRef, useState } from 'react'
import 'amfe-flexible'
import 'normalize.css'
import './App.scss'
import CustomFooter from './comps/footer'
import {
  evokeByTagA,
  defer,
  sleep,
  mobileLog,
  getUserInfo,
  getMobileInfo,
  getData,
  isEmpty,
  isAndroid,
  isiOS,
  getQueryStr
} from './util'
import InView from './lib/in-view'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import { STAT_CONFIG, OPEN_APP_TYPE } from './config'
import { BgTop } from './comps/bg'
import handPng from './assets/coupons/hand.png'
import { productList } from './mock'
function isMoreThanToday(dateString) {
  return +new Date() > +new Date(dateString)
}
const inView = InView()
const inViewForCoupon = InView()
inViewForCoupon.offset({
  bottom: window.innerHeight / 2 - 50,
  top: window.innerHeight / 2
})
/**
 * 根据滚动条，获取每个卡片的距离顶部的距离
 *
 * 或者
 *
 * 根据
 */
const KgMobileCall = window.KgMobileCall
const stat = (a, b, params) =>
  mobileLog(
    STAT_CONFIG.BUSSINESS_ID,
    {
      a,
      b,
      url: window.location.href,
      ...params
    },
    null,
    false
  )
class App extends Component {
  state = {
    path: '',
    carousels: [],
    products: [],
    /**
     * status: 	1:表示已登录,0:未登录，
     * isVIP: 1：是VIP用户,0：不是VIP
     * nickName: 用户昵称
     * userName: 用户名
     * kugouID: 酷狗帐号
     */
    userInfo: {},
    mobileInfo: {},
    showLoading: false,
    openAppType: ''
  }
  /**
   * 生产埋点基础参数
   */
  _genStatisticParams = async (type, id, openType) => {
    const userInfo = await this._getUserInfo()
    const mobileInfo = await this._getMobileInfo()
    const mid =
      (mobileInfo && mobileInfo.mid_v2) || (mobileInfo && mobileInfo.mid) || 0
    const rt = {
      userid: (userInfo && userInfo.kugouID) || 0,
      mid,
      uuid: mobileInfo && mobileInfo.uuid,
      r: STAT_CONFIG.r
    }
    if (null != type) {
      rt.svar1 = type
    }
    if (null != id) {
      rt.svar2 = id
    }
    if (openType != id) {
      rt.svar3 = openType
    }
    return rt
  }
  _clickStat = async (type, id, openAppType) => {
    const params = await this._genStatisticParams(type, id, openAppType)
    stat(STAT_CONFIG.a.CLICK, STAT_CONFIG.b.CLICK, params)
  }
  _exposeStat = async (type, id) => {
    console.log(id, '曝光了')
    const params = await this._genStatisticParams(type, id)
    stat(STAT_CONFIG.a.EXPOSE, STAT_CONFIG.b.EXPOSE, params)
  }
  /**
   * PV  埋点
   */
  _pageViewStat = async () => {
    const params = await this._genStatisticParams()
    stat(STAT_CONFIG.a.PAGE_VIEW, STAT_CONFIG.b.PAGE_VIEW, params)
  }
  async componentDidMount() {
    const { carousels, products } = getData()
    const userInfo = await getUserInfo()
    const mobileInfo = await getMobileInfo()
    const handledProducts = this.handleRatioProduct(products)

    this.setState({
      carousels,
      products: handledProducts,
      userInfo,
      mobileInfo
    })
    this._pageViewStat()
    this._carouselInViewInit()
    this._initCarousels(carousels)
    await this.setOpenType()
    // 如果 url 带 id 则马上自动跳转
    const currentProductId = getQueryStr('id')
    const extractedProductFromUrl = productList[currentProductId]
    if (extractedProductFromUrl) {
      this._clickUrl(
        extractedProductFromUrl.clickUrl,
        STAT_CONFIG.CATA_TYPE.PRODUCT,
        extractedProductFromUrl.id
      )
      // 根据 url 里面的 id，提前产品
      const theProducts = handledProducts.map(theInnerProducts => {
        console.log('products', theInnerProducts)
        return {
          ...theInnerProducts,
          items: theInnerProducts.items.filter(product => product.id !== extractedProductFromUrl.id)
        }
      })
      theProducts[0].items.unshift(extractedProductFromUrl)
      this.setState({
        products: theProducts
      })
      return
    }
  }
  
  setOpenType = async () => {
    const { taobao, tmall } = await getExternalApp()
    this.setState({
      openAppType:
        taobao === 1
          ? OPEN_APP_TYPE.TAO_BAO
          : tmall === 1
          ? OPEN_APP_TYPE.TMALL
          : ''
    })
  }

  _initCarousels = carousels => {
    defer(() => {
      const statCarousel = index => {
        console.log(index)
        this.__carouselIndex = index
        if (this._canExposeCarouselStat) {
          this._exposeStat(STAT_CONFIG.CATA_TYPE.CAROUSEL, carousels[index].id)
          console.log(`carousels _exposeStat success`, carousels[index].id)
        }
      }
      const mySwiper = new Swiper('.swiper-container', {
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        on: {
          init: () => {
            statCarousel(0)
          }
        }
      })
      mySwiper.on('slideChangeTransitionStart', () => {
        statCarousel(mySwiper.realIndex)
      })
    })
  }

  _getUserInfo = async () => {
    if (this.state.userInfo && this.state.userInfo.kugouID) {
      return this.state.userInfo
    } else {
      return getUserInfo()
    }
  }
  _getMobileInfo = async () => {
    if (this.state.mobileInfo && this.state.mobileInfo.mid) {
      return this.state.mobileInfo
    } else {
      return getMobileInfo()
    }
  }
  _clickUrl = async (url, type, id) => {
    const openAppType = this.state.openAppType
    this._clickStat(type, id, openAppType || 'h5')
    if (openAppType) {
      window.callUp.gotoUrl({
        url: url,
        linkKey: openAppType,
        params: {}
      })
    } else {
      await sleep(200)
      KgMobileCall.savePreReturnPage({
        isSave: 1,
        linkUrl: window.location.href
      })
      evokeByTagA(url)
    }
  }
  /**
   * 轮播图曝光初始化
   */
  _carouselInViewInit = () => {
    this._domEnterAndLeave(
      `.swiper-container`,
      () => {
        this._exposeStat(
          STAT_CONFIG.CATA_TYPE.CAROUSEL,
          this.state.carousels[this.__carouselIndex || 0].id
        ) // 需要统计第一次渲染的曝光
        this._canExposeCarouselStat = true
        console.log(
          `carousels _exposeStat success`,
          this.state.carousels[this.__carouselIndex || 0].id
        )
        console.log(`this._canExposeCarouselStat true`)
      },
      () => {
        this._canExposeCarouselStat = false
        console.log(`this._canExposeCarouselStat false`)
      }
    )
    console.log('_carouselInit')
  }

  /**
   * 基础曝光方法
   */
  _domEnterAndLeave = (selector, cb1, cb2) => {
    console.log(selector)
    inView(selector)
      .on('enter', () => {
        cb1 && cb1()
      })
      .on('exit', () => {
        cb2 && cb2()
      })
  }

  /**
   * 获取每个产品的出现区间
   */
  genProductOrigin = products => {
    if (products && products.length > 0) {
      let count = products.reduce((prev, cur) => prev + cur.ratio, 0)
      let curIndex = 0
      let ratioWidth = 1 / count
      for (let index = 0; index < products.length; index++) {
        const curProduct = products[index]
        curProduct.lefOrigin = curIndex
        curProduct.rightOrigin = curIndex + curProduct.ratio * ratioWidth
        curIndex = curProduct.rightOrigin
      }
    }
    console.log(`genProductOrigin ===== `, products)
    return products
  }
  handleRatioProduct = products => {
    if (products && products.length > 0) {
      const randomDigtal = Math.random()
      products = this.genProductOrigin(products)
      const theFirstIndex = products.findIndex(
        item =>
          item.lefOrigin < randomDigtal && item.rightOrigin >= randomDigtal
      )
      products = products.reduce((prev, cur, curIndex) => {
        // 将 theFirstIndex 元素提升到首位
        curIndex === theFirstIndex ? prev.unshift(cur) : prev.push(cur)
        return prev
      }, [])
    }
    console.log(`handleRatioProduct ===== `, products)
    return products
  }

  // 渲染
  render() {
    return (
      <div className="App">
        <BgTop />
        <Carousels carousels={this.state.carousels} clickUrl={this._clickUrl} />
        <Product
          products={this.state.products}
          domEnterAndLeave={this._domEnterAndLeave}
          clickUrl={this._clickUrl}
          exposeStat={this._exposeStat}
        />
        <CustomFooter/>
      </div>
    )
  }
}

export default App

function Carousels({ carousels, clickUrl }) {
  if (carousels && carousels.length <= 0) return null
  const renderCarousel = (carousel, index) => (
    <img
      key={index}
      className="swiper-slide"
      src={carousel.imgurl}
      onClick={() =>
        clickUrl(carousel.clickUrl, STAT_CONFIG.CATA_TYPE.CAROUSEL, carousel.id)
      }
      style={{ backgroundImage: `url(${carousel.imgurl})` }}
    />
  )
  const renderCarousels = carousels
    .filter(item =>
      item.effectiveTime ? isMoreThanToday(item.effectiveTime) : true
    )
    .filter(item =>
      item.offlineTime ? !isMoreThanToday(item.offlineTime) : true
    )
    .map(renderCarousel)
  return (
    <div className="swiper-container">
      <div className="swiper-wrapper">{renderCarousels}</div>
      <div className="swiper-pagination" />
    </div>
  )
}
const HAND_STAY_MIN_TIME_LENGTH = 500
const Coupon = ({
  item,
  productId,
  domEnterAndLeave,
  exposeStat,
  clickUrl,
  showHandId,
  setShowHandId
}) => {
  const [triggerCount, setTriggerCount] = useState(0)
  const timerRef = useRef(null)
  const canShowHand = showHandId === `.coupon-item-${item.id}-${productId}`
  return (
    <div className="coupon-item-wrapper">
      <img
        className={`hand ${canShowHand ? 'show' : 'hide'}`}
        src={handPng}
        alt=""
      />
      <img
        key={item.id}
        className={`coupon-item coupon-item-${item.id}-${productId}`}
        onClick={e => {
          clickUrl(item.clickUrl, STAT_CONFIG.CATA_TYPE.CONPOU, item.id)
          e.stopPropagation()
        }}
        onLoad={() => {
          domEnterAndLeave(`.coupon-item-${item.id}-${productId}`, e => {
            console.log(e)
            exposeStat(STAT_CONFIG.CATA_TYPE.CONPOU, item.id)
          })
          inViewForCoupon(`.coupon-item-${item.id}-${productId}`).on(
            'enter',
            () => {
              setShowHandId(`.coupon-item-${item.id}-${productId}`)
              if (timerRef.current) {
                clearTimeout(timerRef.current)
              }
              timerRef.current = setTimeout(() => {
                setTriggerCount(v => v + 1)
              }, HAND_STAY_MIN_TIME_LENGTH)
              console.log(
                `coupon-item-${item.id}-${productId}`,
                ' ===== inViewForCoupon enter'
              )
            }
          )
        }}
        src={item.imgurl}
        alt="产品图片"
      />
    </div>
  )
}

function Product({ products, domEnterAndLeave, exposeStat, clickUrl }) {
  const [showHandId, setShowHandId] = useState('')
  if (products && products.length <= 0) return null
  const renderProductSingleItem = (item, index, showHandId, setShowHandId) => (
    <div className="single-item" key={item.id}>
      <img
        key={item.id}
        onLoad={() => {
          domEnterAndLeave(`.product-item-${item.id}`, () =>
            exposeStat(STAT_CONFIG.CATA_TYPE.PRODUCT, item.id)
          )
        }}
        className={`product-item product-item-${item.id}`}
        onClick={() =>
          clickUrl(item.clickUrl, STAT_CONFIG.CATA_TYPE.PRODUCT, item.id)
        }
        src={item.imgurl}
        alt="产品图片"
      />
      {item.coupon && (
        <Coupon
          setShowHandId={setShowHandId}
          showHandId={showHandId}
          productId={item.id}
          item={item.coupon}
          exposeStat={exposeStat}
          clickUrl={clickUrl}
          domEnterAndLeave={domEnterAndLeave}
        />
      )}
    </div>
  )

  const renderProduct = (product, productionsIndex) => (
    <div className="product-item-wrap" key={productionsIndex}>
      <img
        src={product.titleImgUrl}
        alt="标题图片"
        className="product-title-img"
      />
      {product.items
        .filter(item =>
          item.effectiveTime ? isMoreThanToday(item.effectiveTime) : true
        )
        .filter(item =>
          item.offlineTime ? !isMoreThanToday(item.offlineTime) : true
        )
        .map((item, index) =>
          renderProductSingleItem(item, index, showHandId, setShowHandId)
        )}
    </div>
  )
  const renderProducts = products.map(renderProduct)

  return <div className="product-container">{renderProducts}</div>
}

function callCmdPromise(cmd) {
  return new Promise((resolve, reject) => {
    return (
      KgMobileCall &&
      KgMobileCall.callCmd &&
      KgMobileCall.callCmd({
        cmd: cmd,
        callback: resolve
      })
    )
  })
}
async function getExternalApp() {
  console.log(isAndroid, isiOS)
  let res = await callCmdPromise(740)
  if (isEmpty(res)) {
    res = await callCmdPromise(300)
  }
  console.log('callCmdPromise getExternalApp', res)
  return res
}
function Close({ onClick }) {
  return (
    <svg
      onClick={onClick}
      t="1560501869222"
      viewBox="0 0 1024 1024"
      version="1.1"
      width="40"
      height="40"
      className="close"
    >
      <path
        fill="#fff"
        d="M670.343843 369.643782c-6.248308-6.248308-16.370853-6.248308-22.61916 0L511.991302 505.376139 376.257921 369.643782c-6.247284-6.248308-16.386202-6.248308-22.61916 0-6.248308 6.247284-6.248308 16.370853 0 22.61916l135.732357 135.732357L353.639784 663.72868c-6.248308 6.248308-6.248308 16.370853 0 22.61916 6.232958 6.248308 16.370853 6.248308 22.61916 0l135.733381-135.733381 135.733381 135.733381c6.248308 6.248308 16.370853 6.248308 22.61916 0s6.248308-16.370853 0-22.61916L534.611485 527.995299l135.733381-135.732357C676.593174 386.014634 676.593174 375.891066 670.343843 369.643782zM511.991302 128.092353c-212.025974 0-383.906623 171.880649-383.906623 383.906623 0 212.026998 171.880649 383.907647 383.906623 383.907647 212.026998 0 383.922996-171.880649 383.922996-383.907647C895.914298 299.973002 724.0183 128.092353 511.991302 128.092353zM511.991302 863.915002c-194.359616 0-351.913978-157.556409-351.913978-351.915002s157.555385-351.913978 351.913978-351.913978c194.344267 0 351.915002 157.555385 351.915002 351.913978S706.335568 863.915002 511.991302 863.915002z"
        p-id="1388"
      />
    </svg>
  )
}
