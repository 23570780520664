const coupons = {
  titleImgUrl: require('./assets/coupon-title.png'),
  B1: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.05hjq0',
    id: 'B-B1-10',
    effectiveTime: '2019-08-29'
  },
  M51: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.05ODVy',
    id: 'B-M51-10',
    effectiveTime: '2019-09-02'
  },
  M3: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.05PBKd',
    id: 'B-M3-10',
  },
  X5: {
    imgurl: require('./assets/coupons/coupon-30.png'),
    clickUrl: 'https://c.tb.cn/c.05lyNk',
    id: 'B-X5-30' 
  },
  X6: {
    imgurl: require('./assets/coupons/coupon-30.png'),
    clickUrl: 'https://c.tb.cn/c.05O941',
    id: 'B-X6-30' 
  },
  X6IP: {
    imgurl: require('./assets/coupons/coupon-20.png'),
    clickUrl: 'https://c.tb.cn/c.058rPY',
    id: 'B-X6IP-20' 
  },
  M1IP: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.059MIe',
    id: 'B-M1IP-10' 
  },
  M1: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.05lSeg',
    id: 'B-M1-10' 
  },
  M1L: {
    imgurl: require('./assets/coupons/coupon-20.png'),
    clickUrl: 'https://c.tb.cn/c.05olLm',
    id: 'B-M1L-20' 
  },
  W1: {
    imgurl: require('./assets/coupons/coupon-20.png'),
    clickUrl: 'https://c.tb.cn/c.05nJ75',
    id: 'B-W1-20' 
  },
  K9: {
    imgurl: require('./assets/coupons/coupon-1000.png'),
    clickUrl: 'https://c.tb.cn/c.05Qi9b',
    id: 'B-K9-1000' 
  },
  C3: {
    imgurl: require('./assets/coupons/coupon-30.png'),
    clickUrl: 'https://c.tb.cn/c.05oY1M',
    id: 'B-C3-30' 
  },
  10: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.0V6xYN',
    id: 'B-10'
  },
  10: {
    imgurl: require('./assets/coupons/coupon-10.png'),
    clickUrl: 'https://c.tb.cn/c.0V6xYN',
    id: 'B-10'
  },
  20: {
    imgurl: require('./assets/coupons/coupon-20.png'),
    clickUrl: 'https://c.tb.cn/c.0VhOXB',
    id: 'B-20'
  },
  30: {
    imgurl: require('./assets/coupons/coupon-30.png'),
    clickUrl: 'https://c.tb.cn/c.0ViZzr',
    id: 'B-30'
  },
  100: {
    imgurl: require('./assets/coupons/coupon-100.png'),
    clickUrl: 'https://c.tb.cn/c.0VT2wo',
    id: 'B-100'
  },
  1000: {
    imgurl: require('./assets/coupons/coupon-1000.png'),
    clickUrl: 'https://c.tb.cn/c.0ViKaU',
    id: 'B-1000'
  }
}
export const productList = {
  B1: {
    imgurl: require('./assets/product/B1.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D600543650977%26mm_sycmid%3D1_21917_8600272e00ecf59afe37df0eca498487',
    id: 'B-B1',
    coupon: coupons['B1'],
    effectiveTime: '2019-08-29'
  },
  M3: {
    imgurl: require('./assets/product/M3.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D600964690207%26mm_sycmid%3D1_22677_67878bb9a064fe781e051216a8ce7fc9',
    id: 'B-M3',
    coupon: coupons['M3'],
    effectiveTime: '2019-09-09'
  },
  M51: {
    imgurl: require('./assets/product/M51.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D601192967498%26mm_sycmid%3D1_21918_d345d7c280640c03cc1bafbfb08a8ba1',
    id: 'B-M51',
    coupon: coupons['M51'],
    effectiveTime: '2019-09-02'
  },
  M1L: {
    imgurl: require('./assets/product/M1L.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D578464690541%26mm_sycmid%3D1_11833_70857769f0bb8c024182acbe4b802536',
    id: 'B-M1L',
    coupon: coupons['M1L']
  },
  M1: {
    imgurl: require('./assets/product/M1.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D524227978030%26mm_sycmid%3D1_11829_304b065fddbd3e806714ead71f921b1f',
    id: 'B-M1',
    coupon: coupons['M1']
  },
  X5: {
    imgurl: require('./assets/product/X5.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D590865770191%26mm_sycmid%3D1_16814_89e3e1f77efaf3ed83e1e973531d6daa',
    id: 'B-X5',
    coupon: coupons['X5']
  },
  X6: {
    imgurl: require('./assets/product/X6.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D593450932355%26mm_sycmid%3D1_16815_6a5d0029042d04e1bd98f3fef4df869d',
    id: 'B-X6',
    coupon: coupons['X6']
  },
  M1IP: {
    imgurl: require('./assets/product/M1IP.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D594685180363%26mm_sycmid%3D1_16816_db1156655612c7c0c204376fb58272ae',
    id: 'B-M1IP',
    coupon: coupons['M1IP']
  },
  X6IP: {
    imgurl: require('./assets/product/X6IP.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D598314773029%26mm_sycmid%3D1_19726_e1809ccd73fd55bec7e67a4b6c39e14b',
    id: 'C-X6IP',
    coupon: coupons['X6IP']
  },
  W1: {
    imgurl: require('./assets/product/W1.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D540856883123%26mm_sycmid%3D1_11830_1bd614715e50efb1ce057dfde33bd2df',
    id: 'B-W1',
    coupon: coupons['W1']
  },
  K9: {
    imgurl: require('./assets/product/K9.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D567336488942%26mm_sycmid%3D1_11832_cfb3227658f65925b558ddc48193edff',
    id: 'B-K9',
    coupon: coupons['K9']
  },
  C3: {
    imgurl: require('./assets/product/C3.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D569328113386%26mm_sycmid%3D1_11831_ee326cc4f6fc624cb743d26c3c470fb3',
    id: 'B-C3',
    coupon: coupons['C3']
  }
}
const carousels = {
  M1:  {
    imgurl: require('./assets/carousel/M1.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D524227978030%26mm_sycmid%3D1_11829_304b065fddbd3e806714ead71f921b1f',
    id: 'A-M1'
  },
  M51_prepare: {
    imgurl: require('./assets/carousel/M51_prepare.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D601192967498%26mm_sycmid%3D1_21918_d345d7c280640c03cc1bafbfb08a8ba1',
    id: 'A-M51',
    effectiveTime: '2019-09-02',
    offlineTime: '2019-09-09'
  },
  M51: {
    imgurl: require('./assets/carousel/M51.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D601192967498%26mm_sycmid%3D1_21918_d345d7c280640c03cc1bafbfb08a8ba1',
    id: 'A-M51',
    effectiveTime: '2019-09-09',
  },
  X5: {
    imgurl: require('./assets/carousel/X5.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D590865770191%26mm_sycmid%3D1_16812_b2a6dcf2fb761c244bec3428218644cb',
    id: 'A-X5'
  },
  X6: {
    imgurl: require('./assets/carousel/X6.png'),
    clickUrl:
      'http://sycm.mmstat.com/gxb.gif?t=https%3A%2F%2Fh5.m.taobao.com%2Fawp%2Fcore%2Fdetail.htm%3Fid%3D593450932355%26mm_sycmid%3D1_16813_53607e6a40b38aecd395bdc5826c9009',
    id: 'A-X6',
    offlineTime: '2019-09-02'
  },
}
const material = {
  // FXIME: id 和 clickUrl 重新获取
  carousels: [ carousels.M51_prepare, carousels.M51, carousels.M1, carousels.X6, carousels.X5 ],

  products: [
    {
      ratio: 1,
      titleImgUrl: require('./assets/product/product-1-title.png'),
      items: [ productList.M51, productList.B1, productList.M3 ]
    },
    {
      ratio: 0,
      titleImgUrl: require('./assets/product/product-2-title.png'),
      // M1L+M1+X5+X6+M1全职+X6全职
      items: [ productList.M1L, productList.M1, productList.X5, productList.X6, productList.M1IP, productList.X6IP]
    },
    
    {
      ratio: 0,
      titleImgUrl: require('./assets/product/product-4-title.png'),
      items: [ productList.W1, productList.K9, productList.C3 ]
    }
  ]
}
export default material
