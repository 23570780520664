export const STAT_CONFIG = {
  BUSSINESS_ID: 30050,
  CATA_TYPE: {
    CAROUSEL: '广告位',
    CONPOU: '优惠券',
    PRODUCT: '商品卡片' 
  },
  r: '酷狗商城',
  b: {
    CLICK: '点击',
    EXPOSE: '曝光',
    PAGE_VIEW: '曝光'
  },
  a: {
    CLICK: 20437,
    EXPOSE: 20436,
    PAGE_VIEW: 20435
  }
}

export const OPEN_APP_TYPE = {
  TAO_BAO: 'TB',
  TMALL: 'TM'
}
