import React from 'react'
import './footer.css'
export default function Footer() {
  return (
    <div className="footerWrapper">
      <div className="footer">
        <div className="copyright">
          <p>
            {/* CopyRight  ©  2019-2021 音超公司 版权所有 | */}

            <a
              hidefocus="true"
              target="_blank"
              href="https://beian.miit.gov.cn/#/Integrated/index"
            >粤ICP 备 09017694号</a>
            &nbsp;&nbsp;&nbsp;
            |
            &nbsp;&nbsp;&nbsp;<span>粤B2-20060339 </span>
            &nbsp;&nbsp;&nbsp;

            |&nbsp;&nbsp;&nbsp;
            <a
              target="_blank"
              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009426"
            >
              <img
                src="http://www.kugou.com/common/images/icon_yuewangga1.png"
                width="24"
                height="24"
              />
              &nbsp;&nbsp;&nbsp;<span>粤公网安备 44010602009426号 </span>


            </a>

            {/* <a
              hidefocus="true"
              target="_blank"
              href="http://air.gzaic.gov.cn:88/outsys/commonquery/viewBusinessLicence?uniscid=91440112304583452X&regno=440112000115133"
            >|
              营业执照
            </a> */}
          </p>
        </div>
      </div>
    </div>
  )
}
