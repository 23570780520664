import mock from './mock'
const ENV = 'development'
let iframe = null
const KgMobileCall = window.KgMobileCall

/**
 * 获取页面隐藏属性的前缀
 * 如果页面支持 hidden 属性，返回 '' 就行
 * 如果不支持，各个浏览器对 hidden 属性，有自己的实现，不同浏览器不同前缀，遍历看支持哪个
 */
function getPagePropertyPrefix() {
  const prefixes = ['webkit', 'moz', 'ms', 'o']
  let correctPrefix

  if ('hidden' in document) return ''

  prefixes.forEach(prefix => {
    if (`${prefix}Hidden` in document) {
      correctPrefix = prefix
    }
  })

  return correctPrefix || false
}

/**
 * 判断页面是否隐藏（进入后台）
 */
function isPageHidden() {
  const prefix = getPagePropertyPrefix()
  if (prefix === false) return false

  const hiddenProperty = prefix ? `${prefix}Hidden` : 'hidden'
  return document[hiddenProperty]
}

/**
 * 获取判断页面 显示|隐藏 状态改变的属性
 */
function getVisibilityChangeProperty() {
  const prefix = getPagePropertyPrefix()
  if (prefix === false) return false

  return `${prefix}visibilitychange`
}

/**
 * 通过 top.location.href 跳转
 * 使用 top 是因为在 qq 中打开的页面不属于顶级页面(iframe级别)
 * 自身 url 变更无法触动唤端操作
 * @param {string}} [uri] - 需要打开的地址
 */
export function evokeByLocation(uri) {
  window.top.location.href = uri
}

/**
 * 通过 iframe 唤起
 * @param {string}} [uri] - 需要打开的地址
 */
export function evokeByIFrame(uri) {
  if (!iframe) {
    iframe = document.createElement('iframe')
    iframe.frameborder = '0'
    iframe.style.cssText = 'display:none;border:0;width:0;height:0;'
    document.body.appendChild(iframe)
  }

  iframe.src = uri
}

/**
 * 通过 A 标签唤起
 * @param {string}} [uri] - 需要打开的地址
 */
export function evokeByTagA(uri) {
  const tagA = document.createElement('a')

  tagA.setAttribute('href', uri)
  tagA.style.display = 'none'
  document.body.appendChild(tagA)

  tagA.click()
}

/**
 * 检测是否唤端成功
 * @param {function} cb - 唤端失败回调函数
 */
export function checkOpen(cb, timeout) {
  const visibilityChangeProperty = getVisibilityChangeProperty()
  const timer = setTimeout(() => {
    const hidden = isPageHidden()
    if (!hidden) {
      cb()
    }
  }, timeout)

  if (visibilityChangeProperty) {
    document.addEventListener(visibilityChangeProperty, () => {
      clearTimeout(timer)
    })

    return
  }

  window.addEventListener('pagehide', () => {
    clearTimeout(timer)
  })
}

export const defer = (fn, ...args) => setTimeout(fn, 1, ...args)
export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

export const mobileLog = (bussiness, countInfo, callback, option) => {
  const data = countInfo
  const sendData = [bussiness, data, callback, option]
  window.apmCollectData = window.apmCollectData || []
  window.apmCollectData.push(sendData)
  console.log('sendData', sendData)
  try {
    newLogCount() // eslint-disable-line
  } catch (ex) {}
}

export const getUserInfo = () => {
  return new Promise((resolve, reject) => {
    if (!KgMobileCall) resolve(undefined)
    KgMobileCall.getUserInfo(res => {
      resolve(res)
    })
  })
}
export const getMobileInfo = () => {
  return new Promise((resolve, reject) => {
    if (!KgMobileCall) resolve(undefined)
    KgMobileCall.getMobileInfo(res => {
      resolve(res)
    })
  })
}

export const getData = () => {
  if (ENV === 'development') {
    return mock
  }
}

export const isEmpty = val => val == null || !(Object.keys(val) || val).length
export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime
  return function() {
    const context = this,
      args = arguments
    if (!inThrottle) {
      fn.apply(context, args)
      lastTime = Date.now()
      inThrottle = true
    } else {
      clearTimeout(lastFn)
      lastFn = setTimeout(function() {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args)
          lastTime = Date.now()
        }
      }, Math.max(wait - (Date.now() - lastTime), 0))
    }
  }
}
const u = navigator.userAgent
export const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
export const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
export function getQueryStr(name) {
  console.log(name)
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = window.location.search.substr(1).match(reg)
  if (r != null) return r[2]
  return null
}
