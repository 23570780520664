import React, { Component } from 'react'
export function BgTop() {
  return (
    <svg width="100%" xmlns="http://www.w3.org/2000/svg" viewBox="0,0,580,350" className="bg-svg">
      <g>
        <title>background</title>
        <rect
          fill="#fff"
          id="canvas_background"
          height="152"
          width="582"
          y="-1"
          x="-1"
        />
      </g>
      <g>
        <title>Layer 1</title>
        <rect
          stroke="rgb(4,144,255)"
          id="svg_2"
          height="120"
          width="585.999979"
          y="-1.042968"
          x="-3.499999"
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="1.5"
          fill="rgb(4,144,255)"
        />
        <ellipse
          stroke="rgb(4,144,255)"
          ry="45.000005"
          rx="313.999976"
          id="svg_6"
          cy="132.957037"
          cx="288.499995"
          fillOpacity="null"
          strokeOpacity="null"
          strokeWidth="1.5"
          fill="rgb(4,144,255)"
        />
      </g>
    </svg>
  )
}
